.table.uk-table td:not(.ecom-direct-access-quickentry-padding) {
    padding: 0;
  }
  
  .table.uk-table td.quickentry-padding {
    padding: 8px 6px 0;
  }
  
  .table.uk-table td.checkbox-padding input {
    margin: 8px 0 0;
  }
  
  .table.uk-table td.checkbox-padding-centered input {
    margin: 8px auto 0;
    display: block;
  }

  .no-padding{
    padding: 0 !important;
  }
  
  .table.uk-table td.quickentry-button {
    padding: 2px 0;
    width: 30px;
  }
  
  .table.uk-table tr.disabled {
    text-decoration: line-through;
    color: #ddd;
  }
  
  .table.uk-table th {
    font-size: 13px;
    padding: 2px 2px 2px 6px;
    color: #333;
    font-weight: normal;
  }
  .overview-column-{
    width: 100px;
  }
  
  .uk-table td,
  .uk-table th {
    border: 0;
  }
  
  .uk-form-horizontal .uk-form-controls,
  .uk-form-horizontal
    .uk-form-controls.uk-form-controls-text-display-only,
  .uk-form-controls,
   .uk-form-controls.uk-form-controls-text-display-only {
    margin: 0;
  }
  
  @media (min-width: 960px) {
    .uk-form-horizontal .uk-form-controls,
    .uk-form-horizontal
      .uk-form-controls.uk-form-controls-text-display-only,
     .uk-form-controls,
     .uk-form-controls.uk-form-controls-text-display-only {
      margin: 0;
      padding: 6px 4px 0;
    }
  }
  .uk-form-horizontal .uk-form-controls,
   .uk-form-controls {
    padding: 0;
  }
  
  @media (min-width: 960px) {
    .uk-form-horizontal  .uk-form-controls,
     .uk-form-controls {
      padding: 0;
    }
  }
  
  @media (min-width: 768px) {
    .uk-description-list-horizontal > dt {
      width: 100px;
      float: left;
      clear: both;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .uk-description-list-horizontal > dd {
      margin-left: 110px;
    }
  }
  
   .remove-entry {
    padding: 6px;
    display: inline-block;
  }
  .paginatied-quickentry-column-rowNo{
    width: 65px;
  }  
  .paginatied-quickentry-column-statNumberSe{
    width: 210px;
  }
  .paginatied-quickentry-column-statNumberNo{
    width: 210px;
  }
  .paginatied-quickentry-column-default{
    width: 162px;
  }
  .paginatied-quickentry-column-default{
    width: 162px;
  }
