.toast-container.toast-bottom-left {
  margin-bottom: 50px;
  margin-left: 65px;
}

.warning {
  background-color: #FCDEA8;
  color: #6E501A !important;
}

.success {
  background-color: #B6EFC3;
  color: #27793A !important;
}

.error {
  background-color: #F09595;
  color: #7B0E0E !important;
}

.info {
  background-color: #BFE4F7;
  color: #236080 !important;
}
