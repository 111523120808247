.uk-modal-dialog {
  width: 600px;
}

@media (min-width: 768px) {
  .uk-modal-dialog-large,
  .uk-modal-dialog-huge {
    width: 930px;
  }
}

@media (min-width: 1220px) {
  .uk-modal-dialog-large,
  .uk-modal-dialog-huge {
    width: 1130px;
  }
}

@media (min-width: 1520px) {
  .uk-modal-dialog-huge {
    width: 1430px;
  }
}

.uk-modal-dialog-small {
  width: 400px;
}

.uk-modal-body .uk-table {
  font-size: 13px;
  line-height: 16px;
}

.uk-modal {
  z-index: 1000;
}
