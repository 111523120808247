.quickentry table.uk-table td:not(.quickentry-padding) {
  padding: 0;
}

.quickentry table.uk-table td.quickentry-padding {
  padding: 8px 6px 0;
}

.quickentry table.uk-table td.checkbox-padding input {
  margin: 8px 0 0;
}

.quickentry table.uk-table td.checkbox-padding-centered input {
  margin: 8px auto 0;
  display: block;
}

.quickentry table.uk-table td.quickentry-button {
  padding: 2px 0;
  width: 30px;
}

.quickentry table.uk-table tr.disabled {
  text-decoration: line-through;
  color: #ddd;
}

.quickentry table.uk-table th {
  font-size: 13px;
  padding: 2px 2px 2px 6px;
  color: #333;
  font-weight: normal;
}

.quickentry .uk-table td,
.quickentry .uk-table th {
  border: 0;
}

.uk-form-horizontal .quickentry .uk-form-controls,
.uk-form-horizontal
  .quickentry
  .uk-form-controls.uk-form-controls-text-display-only,
.quickentry .uk-form-controls,
.quickentry .uk-form-controls.uk-form-controls-text-display-only {
  margin: 0;
}

@media (min-width: 960px) {
  .uk-form-horizontal .quickentry .uk-form-controls,
  .uk-form-horizontal
    .quickentry
    .uk-form-controls.uk-form-controls-text-display-only,
  .quickentry .uk-form-controls,
  .quickentry .uk-form-controls.uk-form-controls-text-display-only {
    margin: 0;
    padding: 6px 4px 0;
  }
}
.uk-form-horizontal .quickentry .uk-form-controls,
.quickentry .uk-form-controls {
  padding: 0;
}

@media (min-width: 960px) {
  .uk-form-horizontal .quickentry .uk-form-controls,
  .quickentry .uk-form-controls {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .quickentry .uk-description-list-horizontal > dt {
    width: 100px;
    float: left;
    clear: both;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .quickentry .uk-description-list-horizontal > dd {
    margin-left: 110px;
  }
}

.quickentry .remove-entry {
  padding: 6px;
  display: inline-block;
}
