uikit-form-element > .uk-form-row {
  padding: 2px;
  min-height: 31px;
}

body .ui-chips > ul.ui-inputtext {
  padding: 0;
  border: 0;
}

body .ui-chips .ui-chips-input-token {
  margin: 0;
  display: block;
}

body .ui-chips > ul.ui-inputtext .ui-chips-input-token input,
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:hover,
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:focus {
  border: 1px solid #e8e8e8;
  padding: 4px 6px;
}

/* @media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 30%;
    margin-top: 7px;
    float: left;
    text-align: right;
  }

  .uk-form-horizontal.uk-form-horizontal-left-align .uk-form-label {
    text-align: left;
  }

  .uk-form-horizontal .uk-form-controls {
    margin-left: 30%;
    padding-left: 15px;
  }

  .uk-form-horizontal .uk-form-controls.uk-form-controls-text {
    padding-top: 7.5px;
    padding-bottom: 2px;
    margin-bottom: 5px;
  }

  .uk-form-horizontal .uk-form-controls.uk-form-controls-check {
    margin-top: 7px;
  }

  .uk-form-controls-text-display-only {
    color: #999;
  }
} */

body
  p-listbox
  .ui-listbox
  .ui-listbox-list
  .ui-listbox-item
  .ui-chkbox.ui-widget {
  display: none;
}

.ukfe,
.ukfe > input,
.ukfe > p-dropdown,
.ukfe > ecom-dropdown,
.ukfe > p-multiSelect,
.ukfe > p-calendar {
  width: 100%;
  min-width: 50px;
}
table .ukfe > p-calendar {
  min-width: 100px;
}

.ukfe.ukfe-xs {
  max-width: 80px;
}

.ukfe.ukfe-sm {
  max-width: 135px;
}

.ukfe.ukfe-md {
  max-width: 200px;
}

.ukfe.ukfe-lg {
  max-width: 300px;
}

.ukfe.ukfe-xs.fixed-size {
  width: 80px;
}

.ukfe.ukfe-sm.fixed-size {
  width: 135px;
}

.ukfe.ukfe-md.fixed-size {
  width: 200px;
}

.ukfe.ukfe-lg.fixed-size {
  width: 300px;
}

.ukfe.ukfe-xs.fixed-min-size {
  min-width: 80px;
  max-width: 100%;
}

.ukfe.ukfe-sm.fixed-min-size {
  min-width: 135px;
  max-width: 100%;
}

.ukfe.ukfe-md.fixed-min-size {
  min-width: 200px;
  max-width: 100%;
}

.ukfe.ukfe-lg.fixed-min-size {
  min-width: 300px;
  max-width: 100%;
}
.uk-form-label {
  position: relative;
}

/* .uk-form-label .required-star {
  position: absolute;
  right: -10px;
  color: red;
} */

body .ukfe.ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-set-width {
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

body .ukfe .ui-dropdown {
  min-width: 0;
}

body .ukfe .ui-dropdown-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

body .ukfe .uk-button {
  padding: 0px 8px;
}

.ui-inputgroup-addon {
  white-space: nowrap;
}

.ui-inputgroup-addon input[type='checkbox'] {
  background-color: #fff;
}

.ukfe .ui-inputgroup-addon input[type='checkbox'] {
  margin: 0;
  vertical-align: text-top;
}

.ukfe .ui-dropdown-label-container {
  max-width: 100%;
}

body .ukfe .ui-state-disabled,
.ukfe input:disabled {
  opacity: 0.8;
}
body .ui-inputgroup .ui-inputgroup-addon:last-child,
body .ui-inputgroup .ui-inputgroup-addon:first-child {
  border-radius: 0;
}
body .ui-inputgroup .ui-inputgroup-addon {
  font-size: 13px;
  display: flex;
  justify-content: center;
  background-color: #e8e8e8;
  padding: 0 10px;
  color: #0d2e40;
}
body .ui-inputgroup .ui-inputgroup-addon > span {
  display: inline-block;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
}

.uk-input {
  font-size: 13px;
}
.uk-form-danger .ui-placeholder,
.uk-form-danger .uk-input {
  color: #f0506e;
}
.uk-form-danger .uk-input,
.uk-form-danger .uk-select,
.uk-form-danger .uk-textarea,
body .uk-form-danger .ui-dropdown {
  border-color: #f0506e;
}
.uk-form-danger label,
.uk-form-success label,
.uk-form-warning label {
  color: #0d2e40;
}
.uk-form-danger p:last-of-type {
  margin: 0;
}
.uk-form-warning .uk-input,
.uk-form-warning .uk-select,
.uk-form-warning .uk-textarea,
.uk-form-warning .ui-dropdown {
  color: #6e501a;
}
body .ui-dropdown:not(.ui-state-disabled):hover {
  border-color: inherit;
}
/* .uk-form-success .uk-input,
.uk-form-success .uk-select,
.uk-form-success .uk-textarea,
body .uk-form-success .ui-dropdown {
  border-color: #32d296;
}
.uk-form-success .ui-placeholder,
.uk-form-success .uk-input,
body .uk-form-success .ui-inputtext {
  color: #32d296;
} */
.btn-fit-content .uk-button {
  width: fit-content;
}
.form-table .quickentry table.uk-table th {
  font-size: 13px;
}
.uk-form-horizontal .uk-form-controls {
  margin: 0;
}
.uk-form-horizontal .uk-form-controls-text {
  padding-top: 0;
}
.uk-width-1-1 > .ui-autocomplete {
  width: 100%;
}
.p-dropdown .p-dropdown-clear-icon {
  right: 20px;
}
.p-dropdown-clear-icon {
  top: 63% !important;
}
.p-dropdown {
  width: 100%;
}
.ukfe,
.ui-inputgroup {
  display: flex;
}
.p-dropdown-panel {
  z-index: 1100 !important;
}
.p-dropdown-panel {
  z-index: 1100 !important;
}
